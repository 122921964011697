.Header {
  padding: 30px 0;
  margin-bottom: 15px;
  text-align: center;

  &.admin {
    padding: 30px 0;
    margin-bottom: 15px;

    .container {
      @extend %flex-justify;
      align-items: center;
    }

    .logo {
      width: 130px;
    }

    .dropdown__btn {
      color: #000;

      &:focus {
        color: $brandPrimary;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}

.logo {
  display: inline-block;
  width: 160px;

  img {
    display: block;
  }
}

@media (max-width: 640px) {
  #Header {
    margin-bottom: 0;
  }
}
