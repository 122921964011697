.card {
  max-width: none;
}

.header {
  @extend %flex-justify;
  align-items: center;
  margin-bottom: 40px;

  h1 {
    margin: 0;
  }

  &__actions {
    display: flex;
  }

  .form-control {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .btn {
    margin-left: 15px;
  }
}

.row {
  display: flex;
  margin: 0 -10px 15px;
  align-items: center;
  text-align: left;

  & > * {
    margin: 0 10px !important;
  }

  &.past > .form-control {
    background-color: #fff3cc;
    opacity: 0.6;
  }

  .dropdown__btn {
    color: $dividerColor;

    &:hover {
      color: rgba(#000, 0.5);
    }

    &:focus {
      color: $brandPrimary;
    }
  }

  select {
    text-transform: capitalize;
  }
}

.select-container {
  display: inline-block;
}

.form-control {
  margin: 0;
}

.footer {
  padding-top: 20px;
  text-align: right;
}

.empty {
  padding-top: 20px;
  color: rgba(#000, 0.5);

  h1 {
    margin-bottom: 15px;
  }

  p {
    max-width: 350px;
    margin: 0 auto;
    font-size: 24px;
  }
}
