.card {
  max-width: 500px;
  border-radius: 7px;
  padding: 30px 60px 40px;
  margin: 0 auto 40px;
  background-color: #fdfdfd;
  text-align: center;
}


@media (max-width: 544px) {
  .card {
    padding: 30px;
  }
}
