.btn {
  display: inline-block;
  border-radius: 7px;
  border: 3px solid $brandPrimary;
  padding: 15px 40px;
  background-color: $brandPrimary;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  appearance: none;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    border-color: gray;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.btn-outline {
  background-color: transparent;
  color: $brandPrimary;
}

.btn-link {
  border: none;
  padding: 0;
  background-color: transparent;
  color: $brandPrimary;
}

.btn-icon {
  display: flex;
  align-items: center;

  span {
    padding-right: 15px;
  }
}

.btn-sm {
  padding: 8px 20px;

  &.btn-outline {
    border-width: 2px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}
