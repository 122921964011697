.dropdown {
  position: relative;

  &__btn {
    padding: 10px;
  }

  &__list {
    display: none;
    width: 200px;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 10px 5px rgba(#000, 0.25);
    z-index: 99;
  }

  &__link {
    @extend .btn;
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 0;
    background-color: #fff;
    text-align: left;
    color: #000;

    &.danger {
      color: $dangerColor;
    }

    &.muted {
      color: $dividerColor;
    }

    &:not(:first-child) {
      border-top: 1px solid $dividerColor;
    }

    &:hover {
      background-color: rgba(#000, 0.05);
    }
  }
}
