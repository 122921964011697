$headerHeight: 100px;

& {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url('/public/img/prayer-bg.jpg');
  background-size: cover;
  color: #fff;

  &.alpha {
    background: #000;

    .header .blur-bg {
      background: transparent;
    }
  }

  &.transparent {
    background: transparent;

    .header .blur-bg {
      background: transparent;
    }
  }
}

h1 {
  margin: 0;
}

.header {
  position: relative;
  overflow: hidden;
  z-index: 2;

  .container {
    @include flex-center();
    height: $headerHeight;
  }

  h1 {
    position: relative;
    z-index: 3;
    font-family: $fontAlt;
    font-size: 62px;
  }
}

.blur-bg {
  width: 100%;
  position: absolute;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background-image: url('/public/img/prayer-bg.jpg');
  background-size: cover;
  background-position: top;
  filter: blur(5px);
  transform: scale(1.6);
  opacity: 0.98;
}

.requestList {
  position: relative;
  z-index: 2;
}

.scrollView {
  height: calc(100% - #{$headerHeight});
}

.request {
  padding: 20px 30px;
  font-size: 52px;
  line-height: 1;
  text-align: center;
  opacity: 0; // Initialize requests as invisible, must make visible in JavaScript

  &:first-child {
    padding-top: 40px;
  }

  &:last-child {
    padding-bottom: 60px;
  }

  .name {
    font-weight: bold;
  }
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.slide-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .slide-cover-container {
    color: #fff;

    h2 {
      font-size: 62px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}