/***************************************************
Avail
***************************************************/
@import "avail/variables";
@import "avail/utils";
@import "avail/base";

/***************************************************
Layouts
***************************************************/
@import "layouts/header";
@import "layouts/footer";

/***************************************************
Components
***************************************************/
@import "components/index";
@import "components/button";
@import "components/form";
@import "components/dropdown";

/***************************************************
Views
***************************************************/
#HomeView {
  @import "views/home";
}

#AuthView {
  @import "views/auth";
}

#RequestsView {
  @import "views/requests";
}

.AdminView {
  @import "views/admin";
}
