#Footer {
  border-top: 1px solid #ccc;
  padding: 40px 0 60px;
  margin-top: 80px;
  text-align: center;

  .logo {
    width: 100px;
  }

  .socials {
    margin-bottom: 5px;

    a {
      padding: 10px 5px;
      font-size: 22px;
      color: #111;
    }
  }
}

.copyright {
  font-size: 14px;
}

.contact {
  line-height: 1.4;
  font-size: 15px;

  a {
    font-family: sans-serif;
    font-size: 16px;
  }

  .separator {
    display: inline-block;

    &:before {
      content: "/";
      padding: 0 10px;
    }
  }
}
