p {
  max-width: 400px;
  margin: 0 auto 15px;
  text-align: center;
  font-size: 14px;
}

.g-recaptcha {
  margin-bottom: 30px;
}

@media (max-width: 392px) {
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
