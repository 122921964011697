@mixin flex-center($direction: 'xy') {
  display: flex;

  @if $direction == 'xy' {
    align-items: center;
    justify-content: center;
  }

  @if $direction == 'y' {
    align-items: center;
  }

  @if $direction == 'x' {
    justify-content: center;
  }
}

%flex-justify {
  display: flex;
  justify-content: space-between;
}
