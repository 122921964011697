label {
  @extend %flex-justify;
  margin-bottom: 5px;
  font-family: sans-serif;
}

textarea {
  resize: vertical;
}

.form-control {
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 30px;
  border: 1px solid $dividerColor;
  padding: 15px;
  font-family: $fontBase;
  font-size: 16px;
  appearance: none;
  outline: none;

  &.dirty {
    background-color: rgba(blue, 0.1);
  }
}

.checkbox {
  line-height: 1;

  input {
    position: absolute;
    opacity: 0;
  }

  input + label {
    display: inline-block;
    margin: 0;
    min-width: 50px;
    width: 50px;
    height: 50px;
    background-color: $bgColor;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }

  i {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    display: none;
  }

  input:hover + label i,
  input:checked + label i {
    display: block;
  }

  input:hover + label {
    background-color: darken($bgColor, 10%);
  }

  input:checked + label {
    background-color: $successColor;
  }
}

.select-container {
  position: relative;

  select {
    min-width: 140px;
    padding-right: 40px;
  }

  .fas {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.error {
  margin-top: -30px;
  margin-bottom: 10px;
  border-radius: 25px;
  padding: 15px;
  color: $dangerColor;
  text-align: left;
}
