* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: $bgColor;
  font-family: $fontBase;
  font-size: 16px;
}

h1 {
  margin-bottom: 30px;
}

p {
  margin-bottom: 30px;
  font-size: 18px;
}

a {
  text-decoration: none;
  color: $brandPrimary;

  &:active,
  &:hover {
    outline-width: 0;
  }
}

address {
  font-style: normal;
}

button {
  outline: none;
}

.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
